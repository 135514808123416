:root {
    --theme: #18479A;
    --white: #ffffff;
    --text: #666666;
    --bg: #a7a7a7;
  }
  
  /* CSS */
  .steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    position: relative;
    margin-top: 40px;
  }
  
  .step-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    background-color: var(--bg);
    transition: .4s;
    color: var(--white);
    margin-right: 10px;
  }
  
  .step-button[aria-expanded="true"] {
    width: 40px;
    height: 40px;
    background-color: var(--theme);
    color: #fff;
  }
  
  
  .done {
    background-color: var(--theme);
    color: #fff;
  }
  
  .step-item {
    z-index: 10;
    text-align: center;
    background: #fff;
    padding-right: 20px;
  }
  
  #progress {
    -webkit-appearance: none;
    position: absolute;
    width: 95%;
    z-index: 5;
    height: 4px;
    margin-left: 18px;
    margin-bottom: 0;
  }
  
  /* to customize progress bar */
  #progress::-webkit-progress-value {
    background-color: var(--theme);
    transition: .5s ease;
  }
  
  #progress::-webkit-progress-bar {
    background-color: var(--bg);
  
  }