@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Prata&display=swap');
@import url('steps.css');
@import url('bootstrap/dist/css/bootstrap.min.css');
@import url('bootstrap-select.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.0/css/all.min.css');
@import url('https://cdn.datatables.net/1.13.4/css/jquery.dataTables.css');
@import url('input-field.css');

:root {
  --theme: #1C47D1;
  --white: #ffffff;
  --text: #666666;
  --secondary: #FFF2F2F2
}

body {
  font-family: 'Lato', sans-serif;
  color: var(--text);
  font-size: 16px;
  font-weight: 400;
}

a {
  text-decoration: unset;
  cursor: pointer;
}

a:hover {
  color: unset;
}

/* ul {
  padding-left: 0;
  list-style: none;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Prata', serif;
  color: var(--theme);
}

.btn-theme {
  background: var(--theme);
  border-radius: 60px;
  font-weight: 500;
  font-size: 18px;
  padding: 16px 40px;
  color: var(--white);
}

.btn-secondary {
  background: var(--white);
  border-radius: 60px;
  font-weight: 500;
  font-size: 18px;
  padding: 16px 40px;
}

.btn-theme:hover {
  color: var(--white);
  border: 2px solid grey;
}

.btn-viewPdf {
  background: var(--theme);
  border-radius: 60px;
  font-weight: 500;
  font-size: 18px;
  padding: 16px 40px;
  color: var(--white);
}

.btn-viewPdf:hover {
  color: var(--black);
  border: 2px solid grey;
}

.btn-viewPdf1 {
  background: var(--theme);
  border-radius: 60px;
  font-weight: 500;
  font-size: 18px;
  /* padding: 16px 40px; */
  color: var(--white);
  width: 100px;
}

.btn-viewPdf1:hover {
  color: var(--black);
  border: 2px solid grey;
}



.btn-theme.btn-outline {
  background: #fff;
  border: 1px solid var(--theme);
  color: var(--theme);
}

.text-theme {
  color: var(--theme);
}

section {
  padding: 40px;
}

.login .content {
  text-align: center;
  padding: 80px;
  height: 100vh;
}

.login .content img {
  width: 300px;
  margin-bottom: 60px;
}

.login .content .text {
  margin-bottom: 40px;
}
.login .card-body {
  padding: 30px;
}
.login .content .text h1 {
  font-size: 44px;
  margin-bottom: 10px;
}

.login .content .text p {
  font-weight: 500;
  font-size: 18px;
}

.login .input-group {
  margin-bottom: 30px;
}

.login .input-group span {
  background: #EBEBEB;
  border: 0;
  border-radius: 6px 0 0 6px;
  height: 50px;
  padding-left: 30px;
  color: var(--theme);
}

.login .input-group input {
  background: #EBEBEB;
  border: 0;
  padding-left: 0;
  border-radius: 0 6px 6px 0;
  font-size: 18px;
}

.login .frgt {
  text-align: end;
  color: var(--theme);
  margin-top: 14px;
  margin-bottom: 40px;
  display: block;
}

.login .right {
  background-image: url("../images/login.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  padding: 30px 40px;
  display: flex;
  align-items: end;
  justify-content: center;
}

.login .right .text {
  font-size: 52px;
  color: #fff;
  text-align: center;
}

.forgot-password .content {
  display: flex;
  flex-direction: column;
}

.forgot-password .content .top {
  flex-grow: 1;
}

.forgot-password .content .text {
  margin-bottom: 80px;
}

.forgot-password .content .fields .input-group {
  margin-bottom: 60px;
}

.navbar {
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.navbar .navbar-brand {
  width: 300px;
}

.navbar .nav-item {
  margin-right: 60px;
}

.navbar .nav-item:last-child {
  margin-right: 0;
}

.navbar .nav-link {
  color: #11254B;
  font-size: 18px;
}

.navbar .nav-link.active {
  color: var(--theme);
  text-decoration: underline;
}

.navbar .profile img {
  width: 40px;
  margin-left: 10px;
}

.listing {
  padding: 30px 20px;
  margin-top: 119px;
}

.listing .title {
  margin-bottom: 40px;
}

.listing .title span {
  font-size: 20px;
  color: var(--theme);
}

.file-upload .text {
  border: 1px dashed;
  padding: 40px 20px;
  border-radius: 6px;
}

.profile.file-upload {
  background: #fff;
  text-align: center;
}

.profile.file-upload img {
  margin-bottom: 20px;
}

/* -----------------------table---------------------- */
table .case-title span.word {
  width: 40px;
  height: 40px;
  display: inline-block;
  background: #E35C3C;
  border-radius: 100%;
  line-height: 40px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
}

table .user-name img {
  width: 40px;
}

table .case-title .value p {
  font-weight: 700;
  margin-bottom: 0;
}

table.dataTable tbody th,
table.dataTable tbody td {
  padding: 18px 10px;
}

.dataTable {
  border: 1px solid #EAECF0;
  border-radius: 14px;
  overflow: hidden;
}

table.dataTable.no-footer {
  border-bottom: unset;
}

.dataTables_filter {
  margin-bottom: 30px;
}

table.dataTable.stripe>tbody>tr.odd>*,
table.dataTable.display>tbody>tr.odd>* {
  background: #F9FAFB;
  box-shadow: unset;
}

td {
  background: #F9FAFB;
}

table.dataTable.order-column>tbody tr>.sorting_1,
table.dataTable.order-column>tbody tr>.sorting_2,
table.dataTable.order-column>tbody tr>.sorting_3,
table.dataTable.display>tbody tr>.sorting_1,
table.dataTable.display>tbody tr>.sorting_2,
table.dataTable.display>tbody tr>.sorting_3 {
  box-shadow: unset !important;
  background: #F9FAFB;
}

table.dataTable.hover>tbody>tr:hover>*,
table.dataTable.display>tbody>tr:hover>* {
  box-shadow: unset;
}

/* -----------------------table---------------------- */
.add-form {
  padding: 40px 20px;
}

.add-form .card {
  background: #FFFFFF;
  box-shadow: 0px 0px 20px rgba(16, 24, 40, 0.15);
  border-radius: 10px;
  padding: 30px 20px;
  margin-bottom: 30px;
}

.add-form .card .title {
  font-size: 32px;
  margin-bottom: 40px;
}

.add-form .card .sub-title {
  font-size: 24px;
  color: var(--theme);
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 30px;
}

.form-group .form-control {
  background: #F2F2F2;
  border: unset;
  border-radius: 6px;
}

.form-group .form-select {
  background-color: #F2F2F2;
  border: unset;
  border-radius: 6px;
  height: 50px;
}

.form-group input.form-control {
  height: 50px;
}

.form-group .label {
  font-size: 18px;
  color: #11254B;
  margin-bottom: 14px;
}

.file-upload {
  background: #f2f2f2;
  border-radius: 6px;
  position: relative;
  padding: 60px 20px;
}

.file-upload input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.file-upload .text i {
  font-size: 36px;
  margin-bottom: 12px;
}

.file-upload.cs-padding {
  padding: 54px 20px;
}

.bd-card {
  border: 1px solid rgba(102, 102, 102, 0.4);
  border-radius: 6px;
  padding: 16px 20px;
}

.add-icon i {
  background: var(--theme);
  border-radius: 6px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: var(--white);
  font-size: 24px;
}

/* .bootstrap-select button {
  height: 50px;
  line-height: 38px;
} */

/* ---------------------responsive--------------------- */

@media (max-width: 1024.98px) {
  .login .content {
    padding: 40px
  }

  .login .right .text {
    font-size: 46px;
    line-height: unset;
  }
}

@media (max-width: 991.98px) {
  .login .order-md {
    order: 2;
  }

  .login .right {
    padding: 40px 40px;
    height: 80vh;
  }

  .login .right .text {
    font-size: 56px;
  }

  .forgot-password .content {
    height: unset;
  }

  .forgot-password .content .top {
    margin-bottom: 40px;
  }
}







/*=== DROPDOWN CSS ===*/

/* .caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent
} */

.dropdown {
  position: relative
}

.dropdown-toggle:focus {
  outline: 0
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175)
}

.dropdown-menu.pull-right {
  right: 0;
  left: auto
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5
}

.dropdown-menu>li>a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap
}

.dropdown-menu>li>a:hover,
.dropdown-menu>li>a:focus {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:hover,
.dropdown-menu>.active>a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #428bca;
  outline: 0
}

.dropdown-menu>.disabled>a,
.dropdown-menu>.disabled>a:hover,
.dropdown-menu>.disabled>a:focus {
  color: #777
}

.dropdown-menu>.disabled>a:hover,
.dropdown-menu>.disabled>a:focus {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false)
}

.open>.dropdown-menu {
  display: block
}

.open>a {
  outline: 0
}

.dropdown-menu-right {
  right: 0;
  left: auto
}

.dropdown-menu-left {
  right: auto;
  left: 0
}

.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #777;
  white-space: nowrap
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990
}

.pull-right>.dropdown-menu {
  right: 0;
  left: auto
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px solid
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px
}

.form-buttons-margin {

  margin-left: 1rem;

}

/* sidebar css */
.top_sidebar {
  position: fixed;
  width: 100%;
  z-index: 1;
}

.sidebar {
  position: fixed;
  top: 75px;
  width: 220px;
  height: 100vh;
  z-index: 1;
  padding-top: 30px;
}

.sidebar_container {
  width: calc(100% - 220px);
  margin-left: 220px;
}

/* patient list */
.patient_list {
  display: inline-block;
  height: calc(100vh - 390px);
  overflow-y: auto;
  width: 100%;
}
.patient_list th{
  white-space: nowrap
}

.patient_list td{
  white-space: nowrap
}

.dialog-content-container {
  display: flex;
  justify-content: center;
  align-items: center
}

.not_available {
  width: 20px;
  height: 20px;
  border: 1px solid rgb(255, 0, 92);
  background-color: rgb(255, 0, 21);
  display: block;
  border-radius: 50%;
}

.info {
  font-size: 18px;
  margin-bottom: 2px;
}

.bold-text {
  font-weight: bold;
  margin-top: 22px;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background-color: var(--theme) !important
}

.MuiPopover-root .MuiPaper-elevation {
  /* max-width: 262px;
  min-width: none;
  max-height: 262px;
  overflow-x: scroll; */
}


.select_des {
  width: 100%;
  max-width: 262px;
  word-wrap: break-word;
  margin-top: 10px !important;
}

.select_des .MuiInputBase-root {
  min-width: 262px;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: unset !important;
}

.tooltip_med .MuiIconButton-root {
  color: #86bd3b;

}

tr.merge td {
  border-top: unset !important;
}

/* tr.merge:first-child td{
  border-top:  1px solid !important;
} */

.file_list_wrraper {
  background: white;
  border: 1px solid #ccc;
  width: 100%;
}

.file_upload_wrap {
  position: relative;
}
.file_list {
  padding: 8px;
  border-bottom: 1px solid #ccc;
}

.file_upload_wrap input {
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0;
  height: 100%;
}
.admin_layout .col-lg-6 {
  margin: 0 auto;
}
.admin_layout .content{
 text-align: center;
}
.rpv-search__highlight--current{
  background-color: rgba(255, 255, 0, 0.5) !important;
  color: black !important;
}
 

#textContainer .button {
  background-color: #4CAF50;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}
#textContainer .button-copy {
  background-color: #008CBA;
}
#textContainer .button-remove {
  background-color: #f44336;
}


.link-with-hover {
  /* Regular link styles */
  color: blue;
  text-decoration: none;
  position: relative; /* Add this line */
}

.link-with-hover:hover {
  /* Hover styles */
  color :#86bd3b;
  text-decoration: underline;
}

/* Show the title text on hover */
.link-with-hover:hover::before {
  content: attr(title);
  position: absolute;
  background-color: #86bd3b;
  color: #fff;
  padding: 5px;
  border-radius: 3px;
  font-size: 12px;
  white-space: nowrap;
  left: -120px; /* Adjust this value to position the text */
  top: 50%;
  transform: translateY(-50%); /* Vertically center the text */
}

.custom-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* higher z-index to make sure it's on top of everything */
}

.custom-dialog-content {
  background-color: #fff; /* white background for dialog content */
  padding: 20px;
  border-radius: 5px;
}


.MuiBox-root.css-0 {
  max-width: 642px !important;

}
.btn-create_password {
  width: 38%;
  display: block;
  margin: auto;
  margin-bottom: 32px;
}
